// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    projectInsertionResponse: [],
    projectList: [],
    requestList: [],
    workFlowDetails: [],
    requestDetails: [],
    dynamicFormFields: [],
    staticTabs: [],
    documentDetails: {},
    response: {},
    coInvesticatorList: [],
    infrastructureList: [],
    comments: [],
    queries: [],
    projBudgetData: [],
    projBudgetTransData: [],
    projBudgetTransHis: [],
    purchaseOfficerRequestList: [],
    purchaseRequestDetails: {},
    accountOfficerRequestList: [],
    fundRequestDetails: {},
    CommitteeList: [],
    committeeDetails: {},
    piDashboardData: []
};

const showAlertMessage = (message, success) => {
    dispatch(
        openSnackbar({
            open: true,
            message,
            alert: {
                color: success ? 'success' : 'error',
                variant: 'filled'
            },
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            close: false
        })
    );
};
const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER LIST
        getUsersList(state, action) {
            state.users = action.payload;
        },
        setProjectInsertionResponse(state, action) {
            state.projectInsertionResponse = action.payload;
        },
        setProjectList(state, action) {
            state.projectList = action.payload;
        },
        setRequestList(state, action) {
            state.requestList = action.payload;
        },
        setComment(state, action) {
            state.comments = action.payload;
        },
        setQueries(state, action) {
            state.queries = action.payload;
        },
        setDocumentDetails(state, action) {
            state.documentDetails = action.payload;
        },
        setworkFlowDetails(state, action) {
            state.workFlowDetails = action.payload;
        },
        setRequestDetails(state, action) {
            state.requestDetails = action.payload;
        },
        setResponse(state, action) {
            state.response = action.payload;
        },
        clearResponse(state) {
            state.response = {};
        },
        setDynamicFormData(state, action) {
            state.dynamicFormFields = action.payload;
        },
        setStaticData(state, action) {
            state.staticTabs = action.payload;
        },
        setCoinvesticatorList(state, action) {
            state.coInvesticatorList = action.payload;
        },
        setInfrastructureList(state, action) {
            state.infrastructureList = action.payload;
        },
        setProjectBudgetData(state, action) {
            state.projBudgetData = action.payload;
        },
        setProjectBudgetTrans(state, action) {
            state.projBudgetTransData = action.payload;
        },
        setBudgetTransHis(state, action) {
            state.projBudgetTransHis = action.payload;
        },
        setPurchaseOfficerRequestList(state, action) {
            state.purchaseOfficerRequestList = action.payload;
        },
        setPurchaseOfficerRequest(state, action) {
            state.purchaseRequestDetails = action.payload;
        },
        setAccountOfficerRequestList(state, action) {
            state.accountOfficerRequestList = action.payload;
        },
        setFundRequestDetails(state, action) {
            state.fundRequestDetails = action.payload;
        },
        setCommitteeList(state, action) {
            state.CommitteeList = action.payload;
        },
        setCommitteeDetails(state, action) {
            state.committeeDetails = action.payload;
        },
        // GET USER LIST
        setPIDashboardData(state, action) {
            state.piDashboardData = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const { clearResponse } = slice.actions;

// ----------------------------------------------------------------------
// export function getUserDetails(id) {
//     return async () => {
//         try {
//             const response = await axios.get(`/getUsedData/${id}`);
//             dispatch(slice.actions.getUserData(response.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function clearUserResult() {
//     return async () => {
//         dispatch(slice.actions.clearUserResult());
//     };
// }

export function uploadProjectDocuments(document) {
    return async () => {
        try {
            let profileImgURLs = [];
            if (document.length > 0) {
                const formData = new FormData();
                formData.append('folderName', 'project');
                document.forEach(({ file }) => {
                    formData.append('documents', file); // Use the same field name for multiple files
                });
                const profileImageResponse = await axios.post(`/uploadMultipleDocuments`, formData);
                profileImgURLs = profileImageResponse?.data?.files.map((file) => file.url);
                return profileImageResponse.data;
            }
            return { success: false };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function insertProjectBasicDetails(data) {
    return async () => {
        try {
            const tempProjectDocument = data?.projectDocument || [];
            // console.log(tempProjectDocument);
            // if (tempProjectDocument.length > 0) {
            //     const documentResponse = await dispatch(uploadProjectDocuments(tempProjectDocument || []));
            //     console.log(documentResponse);
            //     const restructureData = tempProjectDocument.map(({ documentName, createdDate, format, operation, url, file }, index) => {
            //         let tempUrl = url;
            //         if (operation === 'I') {
            //             tempUrl =
            //                 Array.isArray(documentResponse?.files) &&
            //                 documentResponse?.files.find(({ fileName }) => fileName === file?.name)?.url;
            //         }
            //         return {
            //             documentName,
            //             createdDate,
            //             format,
            //             operation,
            //             url: tempUrl
            //         };
            //     });
            //     data.projectDocument = restructureData;
            // }
            const formData = new FormData();
            formData.append('folderName', 'project');
            tempProjectDocument.forEach(({ file }) => {
                formData.append('documents', file); // Use the same field name for multiple files
            });
            formData.append('data', JSON.stringify(data));
            const response = await axios.post('/project', formData);
            const { message, success, code } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error',
                        variant: 'filled'
                    },
                    close: true
                })
            );
            dispatch(slice.actions.setResponse(response.data));
            return success;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function updateProjectBasicDetails(data) {
    return async () => {
        try {
            console.log(data);
            const tempProjectDocument = data?.projectDocument || [];
            // console.log(tempProjectDocument);
            // if (tempProjectDocument.length > 0) {
            //     const documentResponse = await dispatch(uploadProjectDocuments(tempProjectDocument || []));
            //     console.log(documentResponse);
            //     const restructureData = tempProjectDocument.map(({ documentName, createdDate, format, operation, url, file }, index) => {
            //         let tempUrl = url;
            //         if (operation === 'I') {
            //             tempUrl =
            //                 Array.isArray(documentResponse?.files) &&
            //                 documentResponse?.files.find(({ fileName }) => fileName === file?.name)?.url;
            //         }
            //         return {
            //             documentName,
            //             createdDate,
            //             format,
            //             operation,
            //             url: tempUrl
            //         };
            //     });
            //     data.projectDocument = restructureData;
            // }

            const formData = new FormData();
            formData.append('folderName', 'project');
            tempProjectDocument.forEach(({ file }) => {
                formData.append('documents', file); // Use the same field name for multiple files
            });
            formData.append('data', JSON.stringify(data));

            const response = await axios.put('/project', formData);
            const { success, message } = response.data;
            showAlertMessage(message, success);
            dispatch(slice.actions.setResponse(response.data));
            return success;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function commentTheRequest(data) {
    return async () => {
        try {
            const response = await axios.post('/commentRequest', data);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false };
        }
    };
}

export function getComments(id) {
    return async () => {
        try {
            const response = await axios.get(`/commentRequest/${id}`);
            dispatch(slice.actions.setComment(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function downloadDocument(URL) {
    return async () => {
        try {
            // const imageSrc =
            //     'http://localhost:3000/images/Cartoon%20turtle%20posing%20stock%20vector_%20Illustration%20of%20grass%20-%2073472526.jpeg';
            // const image = await fetch(imageSrc);
            // const imageBlog = await image.blob();
            // const imageURL = URL.createObjectURL(imageBlog);
            // const link = document.createElement('a');
            // link.href = imageURL;
            // link.download = 'image file name here';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            const a = document.createElement('a');
            a.href = URL;
            // 'http://localhost:3000/images/Cartoon%20turtle%20posing%20stock%20vector_%20Illustration%20of%20grass%20-%2073472526.jpeg';
            a.download = 'img.jpg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendForApproval(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/sendForApproval', data);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error',
                        variant: 'filled'
                    },
                    close: true
                })
            );
            return { success };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false };
        }
    };
}

export function requestFlowAction(data) {
    return async () => {
        try {
            const response = await axios.post('/requestFlowAction', data);
            const { data: responseData } = response;
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function addProjectDocument(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            formData.append('file', data);
            const response = await axios.post(`/uploadfile`, formData);
            dispatch(slice.actions.setDocumentDetails(response.data));
            // showAlertMessage(message, success);
            return response.data; // Return the response data here
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error; // Rethrow the error to handle it elsewhere if needed
        }
    };
}

export function checkCoinvesticator({ coInvesticatorEmail }) {
    return async () => {
        try {
            const response = await axios.post('/checkCoInvestigator', { coInvesticatorEmail });
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, code: 'error' };
        }
    };
}

export function getCoinvestigatorList(reqID) {
    return async () => {
        try {
            const response = await axios.get(`/getCoinvestigatorList/${reqID}`);
            dispatch(slice.actions.setCoinvesticatorList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function deleteCoInvestigator(data) {
    return async () => {
        try {
            const response = await axios.post('/deleteCoInvestigator', data);
            if (response.data.success) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.data.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: true
                    })
                );
            }
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function getProjectDetails(id) {
    return async () => {
        try {
            const response = await axios.get(`/getProjectDetails/${id}`);
            const tempDynamicFields = JSON.parse(response.data.dynamicFormFields[0].output);
            const tempRequest = JSON.parse(response.data.requestData[0].OUTPUT);
            const tempWFDetails = JSON.parse(response.data.workFlowData[0].output);
            dispatch(slice.actions.setDynamicFormData(tempDynamicFields));
            dispatch(slice.actions.setworkFlowDetails(tempWFDetails));
            dispatch(slice.actions.setRequestDetails(tempRequest));
            dispatch(slice.actions.setStaticData([response.data.staticForm]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectDetailsNew(props) {
    return async () => {
        try {
            const { id, userType } = props || {};

            const response = await axios.get(`/getProjectDetailsNew/${id}/${userType}`);

            // Check if the response contains valid data
            if (response.data.result && response.data.result.length > 0 && response.data.result[0].OUTPUT) {
                const parsedData = JSON.parse(response.data.result[0].OUTPUT);
                parsedData.userType = response.data.userType;
                if (response.data.requestList) {
                    parsedData.budgetDetails = response.data.requestList?.map(({ OUTPUT }) => JSON.parse(OUTPUT)) || [];
                }
                if (parsedData) {
                    dispatch(slice.actions.setRequestDetails(parsedData));
                }
            } else {
                console.log('Invalid response data format');
            }
            return response.data.requestList;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function RequestListCount({ searchTerm = null, filterStatus = null, roleFilterTerm = null } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(
                `/RequestListCount?searchTerm=${searchTerm}&filterStatus=${filterStatus}&roleFilter=${roleFilterTerm}`
            );
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

export function getOtherRequestList({ offset = 0, limit = 10, searchTerm = null, filterStatus = null, roleFilterTerm } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getRequestList`, {
                params: {
                    offset,
                    limit,
                    searchTerm,
                    filterStatus: filterStatus === 'All' ? null : filterStatus,
                    roleFilterTerm: roleFilterTerm === 'All' ? null : roleFilterTerm
                }
            });

            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                const tempList = response.data.result[0].aggregated_json;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setRequestList(tempParsedList));
                return tempParsedList || [];
            }
            // Return an empty array if no data is found
            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Return an empty array on error
            return [];
        }
    };
}
export function getApprovalRequestListWithoutFilter({ offset = 0, limit = 10, searchTerm = null, filterStatus = null } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getRequestListWOFilter`);

            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                const tempList = response.data.result[0].aggregated_json;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setRequestList(tempParsedList));
                return tempParsedList || [];
            }
            // Return an empty array if no data is found
            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Return an empty array on error
            return [];
        }
    };
}
export function ProjectListCount({ searchTerm = null, filterStatus = null } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/ProjectListCount?searchTerm=${searchTerm}&filterStatus=${filterStatus}`);
            console.log(response);
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

export function getProjectList({ offset = 0, limit = 10, searchTerm = null, filterStatus = null } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getProjectList`, {
                params: { offset, limit, searchTerm, filterStatus }
            });

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                const tempList = response.data[0].OUTPUT;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setProjectList(tempParsedList));
                return tempParsedList || [];
            }
            // Return an empty array if no data is found
            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Return an empty array on error
            return [];
        }
    };
}

export function getInfrastructureList(reqID) {
    return async () => {
        try {
            const response = await axios.get(`/getInfrastructureList/${reqID}`);
            dispatch(slice.actions.setInfrastructureList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function makeFundRequest(data) {
    return async () => {
        try {
            //  const response = await axios.post('/makeFundRequest', data);
            //  dispatch(slice.actions.setInfrastructureList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// get project related budget data
export function getProjectBudgetData(projectID) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getProjectBudget/${projectID}`);
            //  const result = response.data.map(({ name, headType }) => ({ value: name, label: name, type: headType }));
            dispatch(slice.actions.setProjectBudgetData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadExternalInverstigatorPhoto(data, type) {
    return async (dispatch) => {
        try {
            if (data) {
                const formData = new FormData();
                formData.append('folderName', type || 'user');
                formData.append('document', data);
                const profileImageResponse = await axios.post(`/uploadSingleDocument`, formData);
                console.log(profileImageResponse);
                return profileImageResponse.data;
            }
            return { success: false };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function createRequest(datas) {
    return async (dispatch) => {
        try {
            // Create a FormData object
            const formData = new FormData();
            // Append JSON data
            formData.append('projectName', datas.projectName);
            formData.append('instituteName', datas.instituteName);
            formData.append('departmentName', datas.departmentName);
            formData.append('projectRefNumber', datas.projectRefNumber);
            formData.append('requestType', datas.requestType);
            formData.append('projectID', datas.projectID);
            formData.append('instDepMapID', datas.instDepMapID);
            formData.append('instituteID', datas.instituteID);
            formData.append('flowType', datas.flowType);
            formData.append('requestDetails', JSON.stringify(datas.requestDetails));
            // Append files
            if (datas.requestDetails?.budgetReqData?.length) {
                // Append files
                datas.requestDetails.budgetReqData.forEach((budget, index) => {
                    if (budget.reimburseAttachment) {
                        formData.append(`reimburseAttachment_${index}`, budget.reimburseAttachment);
                    }
                });
            }

            // Send the request

            const response = await axios.post('/createRequest', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const { data } = response || {};

            const { message, success } = data || {};
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: true
                })
            );
            return { success };
        } catch (error) {
            if (error?.error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error?.error,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getProjectBudgetTrans(projectID, resProjBudgetID) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getBudgetTrans/${projectID}/${resProjBudgetID}`);
            dispatch(slice.actions.setProjectBudgetTrans(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBudgetTransHistory(requestID) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getBudgetTransHistory/${requestID}`);
            const parsedData = JSON.parse(response.data[0].result);
            dispatch(slice.actions.setBudgetTransHis(parsedData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getExternalReviewProjectList() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getExternalReviewProjectList`);
            const { result = [] } = response.data || {};
            return result;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function submitExternalReviewer(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/submitExternalReviwer', data);
            const { data: responseData } = response || {};
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getRecordList() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getRecordList`);
            const { data } = response || {};
            return data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getExternalReviewRecordsForApprovar() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getExternalReviewRecordsForApprovar`);
            const { data } = response || {};
            return data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function submitExternalReview(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/submitExternalReview`, data);
            const { data: responseData } = response || {};
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getExternalReviewForRequest(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getExternalReviewForRequest/${id}`);
            const { data: responseData } = response || {};
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getProjectDetailsForDashboard(props) {
    return async () => {
        try {
            const { id, userType } = props || {};
            const response = await axios.get(`/getProjectDetailsNew/${id}/${userType}`);
            const parsedData = response.data.result[0].OUTPUT ? JSON.parse(response.data.result[0].OUTPUT) : {};
            parsedData.userType = response.data.userType;
            return parsedData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function resendLink(emailID, urlValidity) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/resendLink', { toMail: emailID, urlValidity });
            const { data: responseData } = response || {};
            dispatch(
                openSnackbar({
                    open: responseData?.success,
                    message: responseData?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: responseData?.success ? 'success' : 'error'
                    },
                    close: true
                })
            );
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteExternalReviewerRequest(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/deleteExternalReviewRequest', data);
            const { data: responseData } = response || {};
            dispatch(
                openSnackbar({
                    open: true,
                    message: responseData?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: responseData?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function insertQuery(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/query', data);
            const { data: responseData } = response || {};
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getQueries(projectID) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/query/${projectID}`);
            const { data: responseData } = response || {};
            const { success, result: quriesResult } = responseData || {};
            dispatch(slice.actions.setQueries(quriesResult || []));
            return responseData;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getPurchaseOfficerRequest() {
    return async () => {
        try {
            const response = await axios.get(`/getPurchaseOfficerRequest`);
            if (response.data && Array.isArray(response.data.result) && Object.keys(response.data.result).length > 0) {
                const tempList = response.data.result[0].aggregated_json;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setPurchaseOfficerRequestList(tempParsedList));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function viewPurchaseRequestDetails(data) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const response = await axios.post(`/viewPurchaseRequestDetails`, data);
            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                const tempList = response.data.result[0].OUTPUT;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setPurchaseOfficerRequest(tempParsedList));
            } else {
                dispatch(slice.actions.setPurchaseOfficerRequest([]));
                // Handle empty or invalid response
                dispatch(slice.actions.hasError('Empty or invalid response'));
            }
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function approvePurchaseRequest(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const response = await axios.post(`/approvePurchaseRequest`, details);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function viewFundRequestDetails(id) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const response = await axios.get(`/viewFundRequestDetails/${id}`);
            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                const tempList = response.data.result[0].OUTPUT;
                const tempParsedList = JSON.parse(tempList);
                dispatch(slice.actions.setFundRequestDetails(tempParsedList));
            } else {
                // Handle empty or invalid response
                dispatch(slice.actions.hasError('Empty or invalid response'));
            }
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function releaseFund(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const response = await axios.post('/releaseFund', details);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function completeFundRelease(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const response = await axios.post('/completeFundRelease', details);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function insertPRDetails(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            console.log(details);
            const response = await axios.post('/insertPRDetails', details);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CommitteeBasic(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const formData = new FormData();
            formData.append('committeeName', details.committeeName);
            formData.append('instituteList', details.instituteList); // Assuming this is an array or object
            formData.append('departmentList', details.departmentList); // Assuming this is an array or object
            formData.append('committeeStartDate', details.committeeStartDate);
            formData.append('committeeEndDate', details.committeeEndDate);
            formData.append('committeeDescription', details.committeeDescription);
            formData.append('newCommitteeLogo', details.newCommitteeLogo);
            formData.append('mapID', details.mapID);
            formData.append('basicDetailsEditAccesss', details.basicDetailsEditAccesss);
            formData.append('parentID', details.parentID);
            formData.append('versionID', details.versionID);
            formData.append('committeeStatus', details.committeeStatus);
            const response = await axios.post(`/CommitteeBasic`, formData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CommitteeList({ offset = 0, limit = 10, searchTerm = null, filterStatus = null } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(
                `/CommitteeList?offset=${offset}&limit=${limit}&searchTerm=${searchTerm}&filterStatus=${filterStatus}`
            );
            const { data } = response;
            dispatch(slice.actions.setCommitteeList(data?.result || []));
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CommitteeListCount() {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/CommitteeListCount`);
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CommitteeDetails(versionID) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/CommitteeDetails/${versionID}`);
            const { data } = response;
            const [firstRecord] = data.result || [];
            if (firstRecord.OUTPUT) {
                const parsedOutput = JSON.parse(firstRecord.OUTPUT);
                dispatch(slice.actions.setCommitteeDetails(parsedOutput || {}));
            }
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function InsertCommitteeDocument(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const formData = new FormData();
            formData.append('committeeDocument', details?.committeeDocument);
            formData.append('committeeDocumentType', details?.committeeDocumentType);
            formData.append('versionID', details?.versionID);
            formData.append('parentID', details?.parentID);
            const response = await axios.post(`/InsertCommitteeDocument`, formData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function InsertMeetingDocument(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const formData = new FormData();
            formData.append('committeeDocument', details?.committeeDocument);
            formData.append('committeeDocumentType', details?.committeeDocumentType);
            formData.append('meetingID', parseInt(details?.meetingID, 10));
            formData.append('parentID', details?.parentID);
            const response = await axios.post(`/InsertMeetingDocument`, formData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteCommitteeDocument(documentID) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/deleteCommitteeDocument/${documentID}`);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function AddMembers(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            const formData = new FormData();
            formData.append('committeeDepartmentName', details?.committeeDepartmentName);
            formData.append('parentID', details?.parentID);
            formData.append('committeeEmail', details?.committeeEmail);
            formData.append('committeeInstituteName', details?.committeeInstituteName);
            formData.append('committeeMemberRole', details?.committeeMemberRole);
            formData.append('committeeMemberType', details?.committeeMemberType);
            formData.append('committeePhoneNumber', details?.committeePhoneNumber);
            formData.append('committeeProfileImg', details?.committeeProfileImg);
            formData.append('committeeUserName', details?.committeeUserName);
            (details?.committeeMemebers || []).forEach((member, index) => {
                formData.append(`committeeMemebers[${index}]`, member);
            });
            formData.append('versionID', details?.versionID);
            const response = await axios.post(`/AddMember`, formData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteCommitteeMember(id) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/deleteCommitteeMember/${id}`);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CreateMeeting(details) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            console.log(details);
            const response = await axios.post(`/CreateMeeting`, details);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteCommitteeMeeting(id) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/deleteCommitteeMeeting/${id}`);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function UpdateAttenties(apiData) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.post('/UpdateAttenties', apiData);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function ChangeMeetingStatus(apiData, status) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.post('/ChangeMeetingStatus', { ...apiData, status });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteMeetingDocument(documentID) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/deleteMeetingDocument/${documentID}`);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function CloneCommitteeVersion(versionID) {
    return async (dispatch) => {
        // Add dispatch as a parameter
        try {
            console.log(versionID);
            const response = await axios.post(`/CloneCommitteeVersion`, { versionID });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function deleteProject(projectID, projectName) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/deleteProject/${projectID}`);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.success
                        ? `The ${projectName} deleted successfully`
                        : `${projectName} project has been already initiated`,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: data?.success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return data;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getDashboardDataForPI({ refNumber }) {
    return async () => {
        try {
            const response = await axios.get(`/getDashboardDataForPI/${refNumber}`);
            dispatch(slice.actions.setPIDashboardData(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPurchaseOfficerRequestList({ offset = 0, limit = 10, searchTerm = null, filterTerm = null }) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getPurchaseOfficerRequestList`, {
                params: { offset, limit, searchTerm, filterTerm: filterTerm === 'All' ? null : filterTerm }
            });
            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                dispatch(slice.actions.setPurchaseOfficerRequestList(response.data.result));
                return response?.data?.result;
            }

            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return [];
        }
    };
}

export function getPOListCount({ searchTerm = null, filterTerm = null } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/POListCount?searchTerm=${searchTerm}&filterTerm=${filterTerm}`);
            console.log(response);
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

export function getAOListCount({ searchTerm = null, filterTerm = null } = {}) {
    // Add offset and limit parameters with default values
    return async (dispatch) => {
        try {
            const response = await axios.get(`/AOListCount?searchTerm=${searchTerm}&filterTerm=${filterTerm}`);
            console.log(response);
            const { data } = response;
            return data?.result;
        } catch (error) {
            // Handle Axios error or network error
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

export function getAccountRequestList({ offset = 0, limit = 10, searchTerm = null, filterTerm = null }) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getAccountRequestList`, {
                params: { offset, limit, searchTerm, filterTerm: filterTerm === 'All' ? null : filterTerm }
            });
            if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                dispatch(slice.actions.setAccountOfficerRequestList(response.data.result));
                console.log(response.data.result);
                return response?.data?.result;
            }

            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return [];
        }
    };
}
