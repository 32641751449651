// third-party
import { createSlice } from '@reduxjs/toolkit';
import { openSnackbar } from './snackbar';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    masters: [],
    roleList: [],
    deactivateMaster: [],
    submitMasterDetailsSuccess: [],
    departmentList: [],
    insDeptMapList: [],
    type: [],
    masterData: [],
    userMappedData: [],
    headTypeList: [],
    imageURL: '',
    privateDocument: '',
    budgetMasterData: [],
    institutionWorkflowDetails: [],
    mappedInstWorkflow: [],
    mastersLazyLoading: [],
    fundingAgency: []
};

const slice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Master
        getmasterSuccess(state, action) {
            state.masters = action.payload;
        },
        getFundingAgency(state, action) {
            state.fundingAgency = action.payload;
        },
        setMastersList(state, action) {
            state.mastersLazyLoading = action.payload;
        },

        // GET master DETAILS
        getMasterData(state, action) {
            state.masterData = action.payload;
        },

        // GET master DETAILS
        getMappedInstWorkflow(state, action) {
            state.mappedInstWorkflow = action.payload;
        },

        // GET ROLE LIST
        getRoleCategoryData(state, action) {
            state.roleList = action.payload;
        },

        setType(state, action) {
            state.headTypeList = action.payload;
        },

        // GET department LIST
        getDepartmentData(state, action) {
            state.departmentList = action.payload;
        },

        // GET department map LIST
        getInstDepartmentMap(state, action) {
            state.insDeptMapList = action.payload;
        },

        deactivateMasterData(state, action) {
            state.deactivateMaster = action.payload;
        },

        getsubmitMasterDetails(state, action) {
            state.submitMasterDetailsSuccess = action.payload;
        },
        // GET type based master
        storeType(state, action) {
            state.type = action.payload;
        },
        clearMasterData(state) {
            state.error = null;
            state.masterData = [];
            state.masters = [];
            state.mastersLazyLoading = [];
        },
        clearMasterError(state) {
            state.error = null;
            state.masters = [];
            state.submitMasterDetailsSuccess = [];
        },
        clearsubmitMasterDetailsSuccess(state) {
            state.submitMasterDetailsSuccess = [];
        },
        setMappedUserData(state, action) {
            state.userMappedData = action.payload;
        },
        setImageURL(state, action) {
            state.imageURL = action.payload;
        },
        clearImageURL(state, action) {
            state.imageURL = '';
        },
        setPrivateDocument(state, action) {
            state.privateDocument = action.payload;
        },
        setBudgetData(state, action) {
            state.budgetMasterData = action.payload;
        },
        setinstitutionWorkflowDetails(state, action) {
            state.institutionWorkflowDetails = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const { clearMasterData, clearsubmitMasterDetailsSuccess, clearMasterError, clearImageURL } = slice.actions;

export function clearMasterDetails() {
    return async () => {
        dispatch(slice.actions.clearMasterData());
    };
}

export function getMasters(type, group) {
    return async () => {
        try {
            let response;
            if (type === 'type') {
                response = await axios.get(`/getMasterList/${type}/${group}`);
            } else {
                response = await axios.get(`/getMasterList/${type}`);
            }
            dispatch(slice.actions.getmasterSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMasterDetails(id, type) {
    return async () => {
        try {
            if (id !== null) {
                const response = await axios.get(`/getMasterData/${id}/${type}`);
                dispatch(slice.actions.getMasterData(response.data.masterDetails));
                dispatch(slice.actions.getMappedInstWorkflow(response.data?.mappedWorkflows));
            } else {
                dispatch(slice.actions.getMasterData([]));
                dispatch(slice.actions.getMappedInstWorkflow([]));
                dispatch(slice.actions.setMappedUserData([]));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoleCategoryData(type, typeGroup) {
    return async () => {
        try {
            const response = await axios.get(`/getRoleData/${typeGroup}`);
            if (type === 'profileRole') {
                dispatch(slice.actions.getRoleCategoryData(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHeadTypeData(typeGroup) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getType/${typeGroup}`);
            const result = response.data.data.map(({ name }) => ({ value: name, label: name }));
            dispatch(slice.actions.setType(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deactivateMasterData(id, type) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/deactivate/${id}/${type}`);
            const { message, success } = response.data;
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, error }; // Ensure that a value is returned in the catch block
        }
    };
}

export function submitMasterDetails(formData, type) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/addMaster/${type}`, formData);
            dispatch(slice.actions.getsubmitMasterDetails(response.data));
            return response.data || { success: false };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false };
        }
    };
}

export function SubmitRole(id, data) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/submitRole`, { ...data, id });
            return response.data || { success: false };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false };
        }
    };
}

export function UpdatePermissions(roleID, permissions) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/UpdatePermissions`, { roleID, permissions });
            return response?.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false };
        }
    };
}

export function submitPrimaryUser(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/setPrimaryUser`, data);
            const result = response.data;
            if (result.success) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: result.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false
                    })
                );
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removePrimaryUser(data) {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/removePrimaryUser`, data);
            const result = response.data;
            if (result.success) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: result.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false
                    })
                );
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDepartmentData() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/getDepartment');
            dispatch(slice.actions.getDepartmentData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFundingAgency() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/getFundingAgency');
            dispatch(slice.actions.getFundingAgency(response?.data?.data || []));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstDepartmentMap(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getInstDeptMapData/${id}`);

            dispatch(slice.actions.getInstDepartmentMap(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getType(typeGroup) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getType/${typeGroup}`);
            const result = response.data.data.map(({ name }) => ({ value: name, label: name }));
            dispatch(slice.actions.storeType(result));
            return result;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getMappedUserData(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getUserData/${id}`);
            dispatch(slice.actions.setMappedUserData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function fileUpload(data) {
    return async (dispatch) => {
        // Pass dispatch function as an argument
        try {
            const formData = new FormData();
            formData.append('file', data.uploadedFile);
            const response = await axios.post(`/uploadfile`, formData);
            dispatch(slice.actions.setImageURL(response.data.url));
        } catch (error) {
            console.log(error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'File size exceeded. Max file size is 100KB.',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: false
                })
            );
            dispatch(slice.actions.hasError('File size exceeded. Max file size is 100KB.'));
        }
    };
}

export function getImage(URL) {
    return async (dispatch) => {
        // Pass dispatch function as an argument
        try {
            if (URL) {
                const response = await axios.get(`/${URL}`);
                dispatch(slice.actions.setPrivateDocument(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBudgetData() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getMasterBudget`);
            const result = response.data.map(({ name, headType }) => ({ value: name, label: name, type: headType }));
            dispatch(slice.actions.setBudgetData(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInstitutionMappedWorkflows(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getInstitutionMappedWorkflows/${id}`);
            dispatch(slice.actions.setinstitutionWorkflowDetails(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMastersList({ offset = 0, limit = 10, searchTerm = null, filterTerm = null, type, group = null } = {}) {
    return async (dispatch) => {
        try {
            const tempTerm = filterTerm === 'All' ? '' : filterTerm;
            const response = await axios.get(`/getMasterList`, {
                params: {
                    offset,
                    limit,
                    searchTerm,
                    filterTerm: tempTerm,
                    type,
                    group
                }
            });
            if (response.data) {
                dispatch(slice.actions.setMastersList(response.data.data));
                return response.data.data;
            }
            // Return an empty array if no data is found
            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Return an empty array on error
            return [];
        }
    };
}

export function MasterListCount({ searchTerm = null, filterTerm = null, type, group = null } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/MasterListCount`, {
                params: {
                    searchTerm,
                    filterTerm: filterTerm === 'All' ? null : filterTerm,
                    type,
                    group
                }
            });
            const { data } = response;
            return data?.result;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}
