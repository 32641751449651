// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    users: [],
    userData: [],
    roleList: [],
    departmentList: [],
    institutionList: [],
    userMappingData: [],
    institutionBasedDepartment: [],
    userResponse: {},
    pwdResponse: [],
    referesh: false,
    masterTypeData: [],
    masterStatusData: [],
    projMapCheckResult: [],
    dashboardData: [],
    usersForLazyLaoding: [],
    exportUsers: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER LIST
        getUsersList(state, action) {
            state.users = action.payload;
        },
        getExportUsersList(state, action) {
            state.exportUsers = action.payload;
        },
        getUsersListLazyLoading(state, action) {
            state.usersForLazyLaoding = action.payload;
        },
        // GET USER DETAILS
        getUserData(state, action) {
            state.referesh = true;
            state.userData = action.payload;
        },
        // GET ROLE LIST
        getRoleList(state, action) {
            state.roleList = action.payload;
        },
        // GET DEPARTMENT LIST
        getDepartmentList(state, action) {
            state.departmentList = action.payload;
        },
        // getUsersListStyle2Success(state, action) {
        //     state.usersS2 = action.payload;
        // },
        // GET INSTITUTION LIST
        getInstitutionList(state, action) {
            state.institutionList = action.payload;
        },
        // GET INSTITUTION LIST
        getInstitutionBasedDepartment(state, action) {
            state.institutionBasedDepartment = action.payload;
        },
        // GET USER MAPPING LIST
        getUserMappingData(state, action) {
            state.userMappingData = action.payload;
        },
        setUserDeailsSubmitResponse(state, action) {
            state.userResponse = action.payload;
        },
        clearUserResult(state) {
            state.userResponse = {};
        },
        setReferesh(state) {
            state.referesh = true;
        },
        clearReferesh(state) {
            state.referesh = false;
        },
        // CLEAR USER DATA
        clearUserData(state) {
            state.error = null;
            state.userData = [];
            state.roleList = [];
            state.departmentList = [];
            state.institutionList = [];
            state.userMappingData = [];
            state.projMapCheckResult = [];
        },
        setPasswordRes(state, action) {
            state.pwdResponse = action.payload;
        },
        setUserBasicData(state, action) {
            state.userData = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        clearPwdResponse(state) {
            state.pwdResponse = [];
        },
        setTypeData(state, action) {
            state.masterTypeData = action.payload;
        },
        setStatusData(state, action) {
            state.masterStatusData = action.payload;
        },
        // GET count for project mapping related
        getUserProjData(state, action) {
            state.projMapCheckResult = action.payload;
        },
        // GET USER LIST
        setDashboardData(state, action) {
            state.dashboardData = action.payload;
        }
    }
});

export const { clearReferesh, setUserBasicData, clearPasswordResponse, clearError, clearPwdResponse } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        try {
            const response = await axios.get('/getUsers');
            dispatch(slice.actions.getUsersList(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserDetails(refNumber) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getUsedData/${refNumber}`);
            dispatch(slice.actions.getUserData(response.data));
            const { data } = response;
            return data.length > 0 ? data[0] : {};
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getUsersDetails(refNumbers) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/getUsersData`, refNumbers);
            const { data } = response;
            return data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function clearUserDetails() {
    return async () => {
        dispatch(slice.actions.clearUserData());
    };
}

export function clearUserResult() {
    return async () => {
        dispatch(slice.actions.clearUserResult());
    };
}

export function getMasterData(table) {
    return async () => {
        try {
            const response = await axios.get(`/getActiveMasterList/${table}`);
            const convertedData = response.data.map((item) => ({
                ...item,
                value: item.id,
                label: item.name
            }));
            if (table === 'profileRole') {
                dispatch(slice.actions.getRoleList(convertedData));
            } else if (table === 'department') {
                dispatch(slice.actions.getDepartmentList(convertedData));
            } else if (table === 'institution') {
                const result = response.data.map(({ id, name }) => ({ value: id, label: name }));
                dispatch(slice.actions.getInstitutionList(result));
            } else if (table === 'insBasedDep') {
                const insDepData = response.data;
                const output = insDepData.reduce((acc, item) => {
                    const { institutionId, institutionName, departmentId, departmentName, mapId } = item;
                    if (!acc[institutionId]) {
                        acc[institutionId] = {
                            label: institutionName,
                            value: institutionId,
                            department: []
                        };
                    }

                    acc[institutionId].department.push({
                        mapId,
                        value: departmentId,
                        label: departmentName
                    });

                    return acc;
                }, {});
                dispatch(slice.actions.getInstitutionBasedDepartment(output));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitUserDetails(data) {
    return async () => {
        try {
            let profileImgURL = null;
            if (data?.data.profileLogo) {
                const formData = new FormData();
                formData.append('folderName', 'user');
                formData.append('document', data?.data.profileLogo);
                const profileImageResponse = await axios.post(`/uploadSingleDocument`, formData);
                profileImgURL = profileImageResponse?.data?.url;
            }
            const response = await axios.post(`/addUserDetails`, { ...data, profileImgURL });
            if (response.data.success === true) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.data.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false,
                        alert: {
                            color: 'success', // or 'error', 'warning', 'info'
                            variant: 'filled'
                        }
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.data.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false,
                        alert: {
                            color: 'error', // or 'error', 'warning', 'info'
                            variant: 'filled'
                        }
                    })
                );
            }
            if (response.data.success) {
                dispatch(slice.actions.setUserDeailsSubmitResponse(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserMappingDetails(refNumber) {
    return async () => {
        try {
            const response = await axios.get(`/getInsitutionMappingData/${refNumber}`);
            const modifiedData = response.data.mappingData.map((item) => {
                if (item?.type?.toLowerCase() === 'institution') {
                    return {
                        ...item,
                        InstitutionName: item.insRoleInsttutionName,
                        modification: false,
                        deleted: false
                    };
                }
                return item;
            });
            dispatch(slice.actions.getUserMappingData(modifiedData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitUserMappingDetails(roleBasedId, data, refNumber) {
    return async (dispatch) => {
        try {
            const updatedValue = data.filter(
                (obj) => Object.prototype.hasOwnProperty.call(obj, 'id') && obj.modification === true && obj.deleted !== true
            );
            const insertedValue = data.filter(
                (obj) => !Object.prototype.hasOwnProperty.call(obj, 'id') && obj.modification === true && obj.deleted !== true
            );
            const deletedValue = data.filter((obj) => Object.prototype.hasOwnProperty.call(obj, 'id') && obj.deleted === true);
            const idValues = deletedValue.map((obj) => parseInt(obj.id, 10));
            const allData = {
                roleBasedId,
                insert: insertedValue,
                update: updatedValue,
                delete: idValues,
                refNumber
            };
            const response = await axios.post(`/managingUserMapping`, allData);
            if (response.data.success === true) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.data.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false,
                        alert: {
                            color: 'success', // or 'error', 'warning', 'info'
                            variant: 'filled'
                        }
                    })
                );
                dispatch(slice.actions.getUserData(response.data));
                dispatch(getUserMappingDetails(refNumber)); // Dispatch the action to fetch data
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.data.message,
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        variant: 'alert',
                        close: false,
                        alert: {
                            color: 'error', // or 'error', 'warning', 'info'
                            variant: 'filled'
                        }
                    })
                );
                dispatch(slice.actions.getUserData(response.data));
            }
        } catch (error) {
            console.log('error');
            dispatch(slice.actions.hasError(error));
        }
    };
}
// export function getUsersListStyle2() {
//     return async () => {
//         try {
//             const response = await axios.get('/api/user-list/s2/list');
//             dispatch(slice.actions.getUsersListStyle2Success(response.data.users_s2));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function clearPassword(refNumber) {
    return async () => {
        try {
            await axios.get(`/pwdUpdate/${refNumber}`);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Password has been cleared successfully!',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    variant: 'alert',
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postUserPassword(data) {
    return async () => {
        try {
            const response = await axios.post('/postUsrpwd', data);
            dispatch(slice.actions.setPasswordRes(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTypeData(typeGroup) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getRoleData/${typeGroup}`);
            const result = response.data.map(({ name }) => ({ value: name, label: name }));
            dispatch(slice.actions.setTypeData(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getStatusData(typeGroup) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getRoleData/${typeGroup}`);
            const result = response.data.map(({ name }) => ({ value: name, label: name }));
            dispatch(slice.actions.setStatusData(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deactivateUser(refNumber) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/deactivateUser/${refNumber}`);
            const { message, success } = response.data;
            console.log(response.data);
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: success ? 'success' : 'error'
                    },
                    close: false
                })
            );
            return response.data;
            //  dispatch(slice.actions.deactivateMasterData(response.data)); // Update with response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { error: true, message: error.message }; // Return an error object or any other meaningful value
        }
    };
}

export function getProjMappedCheck(refNumber) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getProjMappedCheck/${refNumber}`);
            dispatch(slice.actions.getUserProjData(response.data));
            const { Count } = response;
            return Count;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getDashboardData({ userType, refNumber }) {
    return async () => {
        try {
            const response = await axios.get(`/getDashboardData/${userType}/${refNumber}`);
            const data = response.data;
            dispatch(slice.actions.setDashboardData({ PI: data?.PI, Approver: data?.Approver, Admin: data?.Admin }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// user list count
export function UserListCount({ searchTerm = null, filterStatus = null, filterUserType = null } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/UserListCount`, {
                params: {
                    searchTerm,
                    filterStatus,
                    filterUserType
                }
            });
            const { data } = response;
            return data?.result;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return 0;
        }
    };
}

// user list
export function getUsersList({ offset = 0, limit = 10, searchTerm = null, filterStatus = null, filterUserType = null } = {}) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/getUsersList`, {
                params: { offset, limit, searchTerm, filterStatus, filterUserType }
            });

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                dispatch(slice.actions.getUsersListLazyLoading(response.data));
                return response.data;
            }
            // Return an empty array if no data is found
            return [];
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Return an empty array on error
            return [];
        }
    };
}

export function exportUsers() {
    return async () => {
        try {
            const response = await axios.get('/exportUsers');
            dispatch(slice.actions.getExportUsersList(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ChangeUserImage(file) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            formData.append('profileImage', file);
            const response = await axios.post(`/ChangeProfileImage`, formData);
            const { data } = response;
            return data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
